import React from "react"
import { Link } from "gatsby"
import FotoKontigoDesktop from "@components/pageCasosdeExito/image/imageCards/FotoKontigoDesktop.png"
import FotoKontigoMobile from "@components/pageCasosdeExito/image/imageCards/FotoKontigoMobile.png"
import FotoNetcallPeruDesktop from "@components/pageCasosdeExito/image/imageCards/FotoNetcallDesktop.png"
import FotoNetcallPeruMobile from "@components/pageCasosdeExito/image/imageCards/FotoNetcallMobile.png"
import FotoRecuperaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaDesktop.png"
import FotoRecuperaMobile from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaMobile.png"
import FotoCulquiDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCulquiDesktop.png"
import FotoCulquiMobile from "@components/pageCasosdeExito/image/imageCards/FotoCulquiMobile.png"
import FotoMorneseDesktop from "@components/pageCasosdeExito/image/imageCards/FotoMorneseDesktop.png"
import FotoMorneseMobile from "@components/pageCasosdeExito/image/imageCards/FotoMorneseMobile.png"
import FotoCostagasDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCostagasDesktop.png"
import FotoCostagasMobile from "@components/pageCasosdeExito/image/imageCards/FotoCostagasMobile.png"
import FotoGualdaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoGualdaDesktop.png"
import FotoGualdaMobile from "@components/pageCasosdeExito/image/imageCards/FotoGualdaMobile.png"
import FotoCasaRosselloDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRosselloDesktop.png"
import FotoCasaRosselloMobile from "@components/pageCasosdeExito/image/imageCards/FotoRosselloMobile.png"
import FotoCafaeDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCafaeDesktop.png"
import FotoCafaeMobile from "@components/pageCasosdeExito/image/imageCards/FotoCafaeMobile.png"
import FotoEnseñaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaDesktop.png"
import FotoEnseñaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaMobile.png"
import FotoRoyalEnfieldDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRoyalDesktop.png"
import FotoRoyalEnfieldMobile from "@components/pageCasosdeExito/image/imageCards/FotoRoyalMobile.png"
import FotoAvivaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAvivaDesktop.png"
import FotoAvivaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAvivaMobile.png"
import FotoCobraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCobraDesktop.png"
import FotoCobraMobile from "@components/pageCasosdeExito/image/imageCards/FotoCobraMobile.png"
import FotoAstaraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAstaraDesktop.png"
import FotoAstaraMobile from "@components/pageCasosdeExito/image/imageCards/FotoAstaraMobile.png"
import FotoIttsaBusDesktop from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusDesktop.png"
import FotoIttsaBusMobile from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusMobile.png"
import FotoSavarDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSavarDesktop.png"
import FotoSavarMobile from "@components/pageCasosdeExito/image/imageCards/FotoSavarMobile.png"
import FotoCepebanDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCepebanDesktop.png"
import FotoCepebanMobile from "@components/pageCasosdeExito/image/imageCards/FotoCepebanMobile.png"
import FotoSynlabDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSynlabDesktop.png"
import FotoSynlabMobile from "@components/pageCasosdeExito/image/imageCards/FotoSynlabMobile.png"
import FotoAunaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAunaDesktop.png"
import FotoProEmpresaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaDesktop.png"
import FotoCyCDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCyCDesktop.png"
import FotoEscampaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEscampaDesktop.png"
import FotoAunaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAunaMobile.png"
import FotoProEmpresaMobile from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaMobile.png"
import FotoCyCMobile from "@components/pageCasosdeExito/image/imageCards/FotoCyCMobile.png"
import FotoEscampaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEscampaMobile.png"


import TitleUnderline from "./titleUnderline"

const LinkCasos = ({ link }) => (
  <div style={{ marginTop: "20px" }}>
    <Link to={link} className="button-link-casos">
      <span>
        Conocer más
      </span>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
      </svg>
    </Link>
    <p className="collaboration">Con colaboración de:</p>
  </div>
)

const CardsCasosExito = ({ data, location }) => (
  <section className="section-cardscasos">
    <p className="text-title-start-postulacion" style={{ textAlign: "center" }}>
      <TitleUnderline underline="Casos de éxito" />
    </p>
    <p className="text-casosexito">
      Estos son algunos de nuestros más importantes casos de éxito
    </p>

    <section className="container-cards-casosexito">

      {/* Contenedor de dos columnas */}
      <div className="flex-container-casosexito">
        {/* Culqui - una sola fila */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section>
              <img
                className="foto hide-component-desktop"
                src={FotoCulquiDesktop}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={FotoCulquiMobile}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section className="descripcion-culqui">
              <p className="tag">Fintech | Telemarketing </p>
              <p className="descripcion">
                Culqi, empresa de pagos digitales, integra por APIs los leads de
                su CRM y los llama automáticamente con Beex. Resultados: +65%
                en contactabilidad y x8 sus ventas de mpos al mes.
              </p>
              <div>
                <LinkCasos link="/casos-de-exito/culqi/" />
                <p className="name-cliente">Paul Vera</p>
                <p>Jefe de telemarketing de Culqi</p>
              </div>
            </section>
          </div>
        </section>
        {/* Dos filas */}
        <div>
          {/* Auna - columna */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <p className="tag">
                  Salud | Atención al cliente
                </p>
                <p className="descripcion">
                  AUNA, cred de clínicas y centros de bienestar en Latam, ha incrementado en 5x tu tasa de conversión con ayuda de Beex, automatizando su gestión y seguimiento de leads por WhatsApp.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/auna/" />
                  <p className="name-cliente">Graciela Zapater Espejo</p>
                  <p>Especialista Digital en AUNA</p>
                </div>
              </section>
              <section className="foto-container">
                <img
                  className="foto hide-component-desktop"
                  src={FotoAunaDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoAunaMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
          {/* ProEmpresa - columna */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoProEmpresaDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoProEmpresaMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <p className="tag"> Banca, Seguros y Finanzas | Atención al cliente</p>
                <p className="descripcion">
                  Financiera Proempresa, ha aumentado su venta de productos financieros en +30% con la ayuda de Beex, optimizando su gestión de leads y masificando su comunicación con campañas masivas por WhatsApp.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/proempresa/" />
                  <p className="name-cliente">Nancy Rodriguez Siancas</p>
                  <p>Marketing Leader</p>
                </div>
              </section>
            </section>
          </section>
        </div>
      </div>
      {/* Astara - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card">
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoAstaraDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoAstaraMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="box-text-testimony">
            <p className="tag">
              Concesionarias | Marketing y ventas
            </p>
            <p className="descripcion">
              Astara, concesionaria líder que representa a marcas como
              Kia, Mitsubishi y Peugeot, ha logrado automatizar sus procesos
              de ventas y remarketing con WhatsApp Business API, aumentando
              en 3x sus ventas digitales.
            </p>
            <div>
              <LinkCasos link="/casos-de-exito/astara/" />
              <p className="name-cliente">Jonny Castillo</p>
              <p>Jefe Comercial de Astara</p>
            </div>
          </section>
        </section>
      </section>
      {/* Ittsabus - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card-mornese component-card">
          <section className="box-text-testimony-mornese box-text-testimony">
            <p className="tag">Logística | Atención al cliente</p>
            <p className="descripcion">
              Savar, compañía de logística especialista en soluciones Door
              to Door, ha automatizado su proceso de notificaciones de
              estado de envíos por WhatsApp, mejorando en +20% la
              satisfacción de sus clientes.
            </p>
            <div>
              <LinkCasos link="/casos-de-exito/savar/" />
              <p className="name-cliente">Dinkar Rios Macedo</p>
              <p>Jefe de Sistemas de Savar</p>
            </div>
          </section>
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoSavarDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoSavarMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </section>
      {/* Contenedor de dos columnas */}
      <div className="flex-container-casosexito reverse">
        {/* Cepeban - una sola fila */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section>
              <img
                className="foto hide-component-desktop"
                src={FotoCepebanDesktop}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={FotoCepebanMobile}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section className="descripcion-culqui">
              <p className="tag">Educación | Marketing y ventas </p>
              <p className="descripcion">
                CEPEBAN, el principal instituto especializado en banca y
                finanzas del Perú, ha sido capaz de mejorar su tasa de
                conversión en +27% en su canal digital, implementando chatbots
                de prospección por WhatsApp.
              </p>
              <div>
                <LinkCasos link="/casos-de-exito/cepeban/" />
                <p className="name-cliente">Alan De La Cruz</p>
                <p>Jefe de Sistemas de CEPEBAN</p>
              </div>
            </section>
          </div>
        </section>
        {/* Dos filas */}
        <div>

          {/* ittsabus - columna */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <p className="tag">Transporte | Atención al cliente</p>
                <p className="descripcion">
                  IttsaBus, importante empresa de transporte terreste en Perú, migró
                  su Contact Center a la nube con Beex. Con esto, logró mejorar su
                  atención al cliente y la satisfacción de sus usuarios en
                  reprogramación de viajes en 22%.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/ittsa-bus/" />
                  <p className="name-cliente">Iris Pérez Medina</p>
                  <p>Coordinadora de Marketing y Servicio al Cliente</p>
                </div>
              </section>
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoIttsaBusDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoIttsaBusMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
          {/* Aviva - columna */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoAvivaDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoAvivaMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <p className="tag">Salud | Atención al cliente</p>
                <p className="descripcion">
                  Clínica Aviva, ha logrado automatizar su proceso de gestión de
                  citas médicas con Beex. Ahora sus pacientes son capaces de
                  agendar, reagendar y cancelar sus citas hasta 30% más rápido
                  por el canal que más usan, WhatsApp.
                </p>

                <div>
                  <LinkCasos link="/casos-de-exito/clinica-aviva/" />
                  <p className="name-cliente">Luis Ordinola Díaz</p>
                  <p>Coordinador de Mejora de Procesos de Clínica Aviva</p>
                </div>
              </section>
            </section>
          </section>
        </div>
      </div>
      {/* Kontigo - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card">
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoKontigoDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoKontigoMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="box-text-testimony">
            <p className="tag">Fintech | Atención al cliente </p>
            <p className="descripcion">
              Kontigo, es capaz de cerrar el 98% de sus casos de atención al
              cliente en un primer contacto. Descubre cómo esta Fintech lo logra
              con Beex.
            </p>

            <div>
              <LinkCasos link="/casos-de-exito/kontigo/" />
              <p className="name-cliente">Analucía Siguas Velásquez </p>
              <p> Gerente de Marketing y Producto en GMoney </p>
            </div>
          </section>
        </section>
      </section>
      {/* Mornese - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card-mornese component-card">
          <section className="box-text-testimony-mornese box-text-testimony">
            <p className="tag">Contact Center | Cobranzas</p>
            <p className="descripcion">
              Mornese, con operaciones de Contact Center a nivel global, ha
              logrado automatizar sus procesos con las integraciones nativas y
              personalizadas de Beex. Conoce como ahora recorre 3X más rápido
              sus campañas.
            </p>

            <div>
              <LinkCasos link="/casos-de-exito/mornese/" />
              <p className="name-cliente">Noelia Molina</p>
              <p>Gerente de Planeamiento y Estrategias</p>
            </div>
          </section>
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoMorneseDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoMorneseMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </section>
      {/* Contenedor de dos columnas */}
      <div className="flex-container-casosexito">
        {/* Grupo CyC - una sola fila */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section>
              <img
                className="foto hide-component-desktop"
                src={FotoCyCDesktop}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={FotoCyCMobile}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section className="descripcion-culqui">
              <p className="tag">
                {" "}
                Atención al cliente | Marketing y ventas
              </p>
              <p className="descripcion">
                Grupo CyC, ha mejorado sus principales indicadores de ecommerce con Beex, mejorando su estrategia de remarketing y crosseling, logrando aumentar en +40% sus ventas digitales.
              </p>
              <div>
                <LinkCasos link="/casos-de-exito/cyccomputer/" />
                <p className="name-cliente">Oskar Martinez</p>
                <p>Marketing Leader</p>
              </div>
            </section>
          </div>
        </section>
        {/* Dos filas */}
        <div>
          {/* Cobra - columna */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoCobraDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoCobraMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <p className="tag">
                  Telecomunicaciones | Atención al cliente
                </p>
                <p className="descripcion">
                  Grupo Cobra, ha mejorado en +20% su contactabilidad en comparación
                  al canal telefónico, con la API de WhatsApp Business. Conoce como
                  es capaz de contactar con sus usuarios de manera efectiva con
                  Beex.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/grupo-cobra/" />
                  <p className="name-cliente">Luis Pasco Ramirez</p>
                  <p>Jefe de Operaciones en Grupo Cobra</p>
                </div>
              </section>
            </section>
          </section>
          {/* Rossello - columna */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <p className="tag">Ecommerce | Marketing y ventas</p>
                <p className="descripcion">
                  Casa Rosselló, ha centralizado sus comunicaciones de WhatsApp,
                  Livechat, Instagram y más con nuestra plataforma de Beex;
                  logrando aumentar en mas del 75% sus leads bien prospectados
                  desde canales digitales.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/casa-rossello/" />
                  <p className="name-cliente">Marlen Vega</p>
                  <p>Jefa de Marketing de Casa Rosselló</p>
                </div>
              </section>
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoCasaRosselloDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoCasaRosselloMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
        </div>
      </div>
      {/* Cafae - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card">
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoCafaeDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoCafaeMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="box-text-testimony">
            <p className="tag">
              Gobierno | Atención al cliente | Marketing y ventas
            </p>
            <p className="descripcion">
              Conoce como CAFAE-SE, logra gestionar eficientemente
              interacciones por WhatsApp, atendiendo el 60% de sus consultas
              sin intervención de agentes.
            </p>
            <div>
              <LinkCasos link="/casos-de-exito/cafae-se/" />
              <p className="name-cliente">Johans Herencia</p>
              <p>Responsable de Marketing Digital en CAFAE-SE</p>
            </div>
          </section>
        </section>
      </section>
      {/* Netcall - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card-mornese component-card">
          <section className="box-text-testimony-mornese box-text-testimony">
            <p className="tag">Contact Center | Televentas </p>
            <p className="descripcion">
              Mira cómo la operación de Netcall Perú, administra sus
              campañas de más de 300 teleoperadores con Beex, logrando asi
              aumentar su contactabilidad en +25% y duplicar sus ventas
              mensuales.
            </p>

            <div>
              <LinkCasos link="/casos-de-exito/netcall-peru/" />
              <p className="name-cliente">Humberto Chumán</p>
              <p>Jefe de Proyectos en Netcall</p>
            </div>
          </section>
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoNetcallPeruDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoNetcallPeruMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </section>











      {/* Contenedor de dos columnas */}
      <div className="flex-container-casosexito reverse">
        {/* Royal - una sola fila */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section>
              <img
                className="foto hide-component-desktop"
                src={FotoRoyalEnfieldDesktop}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={FotoRoyalEnfieldMobile}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section className="descripcion-culqui">
              <p className="tag">
                Concesionarias | Marketing y ventas
              </p>
              <p className="descripcion">
                Royald Enfield, marca de motocicletas reconocida a nivel
                mundial, centraliza las comunicaciones digitales de sus tiendas
                y franquicias con nuestra plataforma de Beex, logrando
                aumentar sus leads bien prospectados en +27%.
              </p>

              <div>
                <LinkCasos link="/casos-de-exito/royal-enfield/" />
                <p className="name-cliente">Liliana López</p>
                <p>Jefe de Marketing de Royal Enfield</p>
              </div>
            </section>
          </div>
        </section>
        {/* Dos filas */}
        <div>
          {/* Synlab - columna */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoSynlabDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoSynlabMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <p className="tag">Salud | Atención al cliente</p>
                <p className="descripcion">
                  SYNLAB Perú, operación peruana del laboratorio clínico más
                  grande de Europa, ha mejorado su tiempo de primera respuesta
                  en 95% gracias a Beex, logrando centralizar y automatizar su
                  servicio al cliente.
                </p>

                <div>
                  <LinkCasos link="/casos-de-exito/synlab/" />
                  <p className="name-cliente">Yesenia Sánchez Rodriguez</p>
                  <p>Jefa de Servicio al Cliente en SYNLAB</p>
                </div>
              </section>
            </section>
          </section>
          {/* Costagas - columna */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <p className="tag">
                  Masivo | Atención al cliente | Marketing y ventas
                </p>
                <p className="descripcion">
                  Costagas, reconocida marca de la industria energética, eligió
                  a Beex para automatizar sus comunicaciones. Resultados: 1.3x
                  más ventas y reducción a 1min tiempo de primera respuesta.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/costagas/" />
                  <p className="name-cliente">Natalia Pachas</p>
                  <p>Jefa de Marketing de Costagas</p>
                </div>
              </section>
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoCostagasDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoCostagasMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
        </div>
      </div>
      {/* Cafae - una sola fila */}
      <section className="rows-container-testimony">
        <section className="component-card">
          <section className="box-img-testimony">
            <img
              className="foto hide-component-desktop"
              src={FotoEscampaDesktop}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={FotoEscampaMobile}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="box-text-testimony">
            <p className="tag">
              Fintech | Atención al cliente |
            </p>
            <p className="descripcion">
              Escampa, fintech mexicana que brinda soluciones financieras para aliviar el sobre endeudamiento, ha mejorado en +30% su CSAT con la ayuda de Beex, sumando automatización y comunicaciones masivas por WhatsApp, a través del API oficial.
            </p>
            <div>
              <LinkCasos link="/casos-de-exito/escampa/" />
              <p className="name-cliente">Gabriel Gmora Backal</p>
              <p>Socio Fundador de Escampa</p>
            </div>
          </section>
        </section>
      </section>


      {/* Contenedor de dos columnas */}
      <div className="flex-container-casosexito reverse">
        {/* Recupera - una sola fila */}
        <section className="rows-container-testimony">
          <div className="card-culqui">
            <section>
              <img
                className="foto hide-component-desktop"
                src={FotoRecuperaDesktop}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={FotoRecuperaMobile}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section className="descripcion-culqui">
              <p className="tag">Contact Center | Cobranzas</p>
              <p className="descripcion">
                Mira cómo Recupera, grupo líder de cobranzas en Perú, aumento en
                +90% su tasa de adherencia y +97% su tasa de ocupación con el
                marcador Predictivo con Machine Learning de Beex.
              </p>

              <div>
                <LinkCasos link="/casos-de-exito/grupo-recupera/" />
                <p className="name-cliente">Marco Salinas</p>
                <p>Gerente Comercial de Recupera</p>
              </div>
            </section>
          </div>
        </section>
        {/* Dos filas */}
        <div>
          {/* Gualda - columna */}
          <section
            className="rows-container-testimony"
            style={{ marginBottom: "20px" }}
          >
            <section className="card-netcall">
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoGualdaDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoGualdaMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
              <section>
                <p className="tag">Educación | Marketing y ventas </p>
                <p className="descripcion">
                  Descubre como una empresa de crecimiento acelerado ha logrado
                  mejorar la tasa de conversión de leads digitales hasta en +27%
                  con Beex.
                </p>

                <div>
                  <LinkCasos link="/casos-de-exito/gualda/" />
                  <p className="name-cliente">Axel Gualda</p>
                  <p>CEO & Founder de Gualda Training</p>
                </div>
              </section>
            </section>
          </section>
          {/* Enseña - columna */}
          <section className="rows-container-testimony">
            <section className="card-recupera">
              <section>
                <p className="tag">ONG | Telemarketing</p>
                <p className="descripcion">
                  Enseña Perú, ha logrado aumentar la cantidad de donantes a su
                  movimiento social que transforma la educación de los niños más
                  necesitados. Mira como Beex los ha ayudado con este fin.
                </p>
                <div>
                  <LinkCasos link="/casos-de-exito/ensena-peru/" />
                  <p className="name-cliente">Omar García</p>
                  <p>Coordinador del Programa de Suscriptores en Enseña Peru</p>
                </div>
              </section>
              <section>
                <img
                  className="foto hide-component-desktop"
                  src={FotoEnseñaDesktop}
                  alt="foto"
                  loading="lazy"
                />
                <img
                  className="foto hide-component-mobile"
                  src={FotoEnseñaMobile}
                  alt="foto"
                  loading="lazy"
                />
              </section>
            </section>
          </section>
        </div>
      </div>
    </section>

    <div className="cardscasosexito">
      {data.map((elemento, index) => {
        return (
          <section id={elemento} key={index} className="style-cardscasos">
            <img loading="lazy" src={elemento.image} alt="casos-exito" />
            <p className="tag">{elemento.tag}</p>
            <p className="textcards-casosexito">{elemento.textbody}</p>
            <Link to={`${elemento.link}${location.search}`} className="button-link-casos" style={{ textAlign: 'center' }}>
              <span>
                Conocer más
              </span>
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
              </svg>
            </Link>
          </section>
        )
      })}
    </div>
  </section>
)
export default CardsCasosExito
